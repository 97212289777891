@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,500;1,100&display=swap');


html {
  height: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body, html {
  height: 100vh;
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: #000;
  position: relative;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
  background-image: url('./background2.png');
  background-size: cover;
  &.modal-open {
    overflow-y: hidden;
    padding-right:5px;
    height: 100%;
    touch-action: none;
-ms-touch-action: none;

  }
}


h1 {
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
}
.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: inset 0 0 200px 150px #000;
}
.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 90%;
  max-width: 500px;
  text-align: center;
  p {
    font-family: 'IBM Plex Sans', sans-serif;
    color: #fff;
    font-weight: 100;
  }
  a {
    color: #91f3ff;
    text-decoration: none;
  }
}